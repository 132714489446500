<template>
	<div>
		<img src="../../../assets/imges/index/28.png" class="headerimg" />
		<div class="dynamic">
			
			<div class="dynamicheader">
				<span class="title1">荣誉资质</span><span class="arrows">></span>
				<span class="title2" @click="getlist('1')">资质列表</span>
			</div>
			<div class="dynamicconter">
				<div class="dynamiccontercard">
					<el-timeline class="dynamtimeline">
						<el-timeline-item v-for="(activity, index) in activities" :key="index" :icon="activity.icon" size="large"
						 :hide-timestamp="true" placement="top" @click.native="timegetlist(activity.id)" :color="time ==activity.id?'#2F7FFC':'' ">
							<div class="timeline" :style="{color:time ==activity.id?'#2F7FFC':'' }">{{activity.name}}</div>
						</el-timeline-item>
					</el-timeline>
				</div>
		
				<div>
					<div class="list" style="width: 100%;">
								<div class="cardlist">
									<div class="card" v-for="(item,index) in dynamicinfolist" :key='index'>
										<el-image :src="item.fileId"></el-image>
										<div class="name">{{item.name}}</div>
									</div>
								</div>
								
							</div>
					<Pagination :pagesize="pagesize" :total="total" @chang="handleSizeChange"></Pagination>
				</div>
			</div>
			
		</div>
	</div>
</template>

<script>
	import {QualificationsQuery,QualificationTypesList} from '../../../api/index.js'
	import Pagination from '../../../components/Pagination'
	export default {
		components: {
			Pagination
		},
		data() {
			return {
				total: 100,
				pagesize: 9,
				pageno:1,
				time: '', //选中的时间
				activities: [
					
				],
				dynamicinfolist: [], 
			}
		},
		mounted() {
			let val = this.$route.query.id
			QualificationTypesList().then(res=>{
				this.activities= res.data.rows
				if(val){
					this.time = val
				}else{
					this.time = this.activities[0].id
				}
				
				this.getlist()
			})
		},
		methods: {
			timegetlist(time) {
				this.time = time
				this.getlist()
			},
			getlist() {
				QualificationsQuery({
					QualificationTypeId:this.time,
					PageNo:this.pageno,
					PageSize:this.pagesize
				}).then(res=>{
					this.total= res.data.totalRows
					this.dynamicinfolist= res.data.rows
				})
			},
			handleSizeChange(val) {
				this.pageno = val
				this.getlist()
			}
		}
	}
</script>

<style scoped lang="less">
	.dynamicconter /deep/.el-timeline-item__node--large {
		width: 18px !important;
		height: 18px !important;
	}

	.dynamicconter /deep/.el-timeline-item__tail {
		margin-left: 2px;
	}

	.dynamiccontercard{
		min-width: 221px;
	}
	
	/deep/.Pagination{
		    // margin-top:22px;
			// padding-left: 102px;
		    // float: none;
		    // padding-bottom: 3.125rem;
	}
	
	.dynamic {
		width: 80%;
		margin: 0 auto;
		margin-bottom: 125px;
		.dynamicheader {
			padding: 59px 0;
			font-size: 28px;
			border-bottom: 1px solid #979797;

			.title1 {
				font-family: SourceHanSansCN-Bold, SourceHanSansCN;
				font-weight: bold;
			}

			.arrows {
				color: #BEBEBE;
				padding-left: 21px;
			}

			.title2 {
				font-weight: 500;
				margin-left: 48px;
				color: #B4B4B4;
				cursor: pointer;
				color: #2F7FFC;
			}
		}

		.dynamicconter {
			margin-top: 70px;
			display: flex;
			.dynamtimeline {
				margin-top: 33px;

				.timeline {
					height: 71px;
					font-size: 16px;
					font-weight: 500;
					color: #B4B4B4;
					cursor: pointer;
				}
			}
			
			.list{
				border-left: 1px solid #979797;
				padding-left: 65px;
				margin-left: 82px;
				width: 88%;
				box-sizing: border-box;
				.cardlist {
					display: flex;
					// justify-content: space-between;
					flex-wrap:wrap;
					.card {
						// height: 305px;
						font-size: 14px;
						font-weight: 500;
						// line-height: 21px;
						margin-left: 60px;
						margin-top: 12px;
						.el-image{
							min-width:305px;
							min-height: 212px;
							max-height: 500px;
							max-width: 305px;
							img{
								width: 100%;
								height: 100%;
							}
						}
						.name{
							margin-top: 30px;
							text-align: center;
						}
					}
				}
			}
			
		}
	}
</style>
